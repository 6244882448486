/* Reset default styles and set black background */
html, body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

/* Ensure all root elements maintain black background */
#root {
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
}

/* Remove default blue highlight on mobile devices */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Remove blue highlight on links and buttons */
a, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

